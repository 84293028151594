// 個人情報保護方針

<template lang="pug">
.public-privacy

  header.bg-teal-300
    h1.text-teal-800.text-center.font-bold.p-2 おもいでBook作成サイト

  .container.mx-auto.p-3.pb-20.mt-4

    h2.text-3xl.font-bold.text-teal-700.lg__text-center 個人情報保護方針

    p.mt-6 写真の伊里では、以下に掲げる方針に基づいてお客様の個人情報を取り扱っております。

    .mt-6

      h2.text-lg.font-bold 個人情報保護の適用範囲

      p.mt-2 各団体に所属する方が、写真の伊里（以下、「弊社」とします）上にあるサービスを利用する上で、弊社が取得した個人情報は、本ページに掲げる個人情報保護方針に従って管理されます。

      p.mt-2
        | なお、個人情報とは、以下のような内容を指します。
        br
        | 「団体名」「行事名」「会員番号・学生番号など識別可能な番号」「氏名」「住所」「電話番号」「商品の注文コード」「メールアドレス」

      h2.mt-4.text-lg.font-bold 個人情報の利用目的

      p.mt-2 各団体に所属する方が、おもいでBook等を購入することを目的としています。

      h2.mt-4.text-lg.font-bold 個人情報の安全対策

      p.mt-2 弊社の各サービスでは、お客様の個人情報を、正確、最新のものとするよう適切な処置を講じます。

      p.mt-2 また、お客様の情報への不当なアクセスなどを防止するための措置万全の体制及び個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を整え、個人情報の管理体制に関して継続的に改善に努めてまいります。

      h2.mt-4.text-lg.font-bold お客様の個人情報の第三者提供

      p.mt-2 弊社は、商品配送を外部の企業へ委託しており、商品配送に必要な最小限の情報(住所、氏名、電話番号)を提供しておりますが、その提供に当たっては、情報の安全管理が図られるよう、当該事業者に対する必要かつ適切な管理を行うこととしております。

      p.mt-2 上記以外につきましては、弊社は、お客様の事前承認なく、お客様の個人情報を第三者に開示・提供することはありません。第三者との間での共同利用や業務委託先に個人情報を提供する場合は、別途、提供先について調査し、個人情報の適切な処理・管理を行います。

      h2.mt-4.text-lg.font-bold 個人情報の開示

      p.mt-2 弊社では、下記の場合を除き個人情報を開示することはありません。但し、弊社のサービスを運営する上で、必須であり、必要かつ相当な範囲での開示は除かれます。

      p.mt-2
        | ・集計および分析等により得られたものを、個人を識別または特定できない態様にて提携先等第三者に開示または提供する場合
        br
        | ・任意に「利用者」等の同意を得た上で個人情報を開示または利用する場合
        br
        | ・裁判所の発する令状その他裁判所の決定、命令または法令に基づき開示する場合
        br
        | ・検察・警察・監督官庁からの適法・適式な手順により情報の照会があった場合

    footer.mt-10.text-center
      button.btn(
        @click='$router.go(-1)'
      ) 戻る

</template>

<script>
export default {
  name: 'PublicPrivacy'
}
</script>

<style lang="sass" scoped>

</style>
